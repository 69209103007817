import anime from "animejs";
import Alpine from 'alpinejs'

window.Alpine = Alpine
Alpine.start()

/*
anime({
    targets: 'img',
    translateY: -120,
})
*/

console.log('                                          \n' +
    '                      \'llc\' .,,\'.  ...    \n' +
    '                      lWMMx.:XWWO\'\'kK0x\'  \n' +
    '                      lWMMx.:NMM0\'\'0MWX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '                      lWMMx.:NMM0\'\'0MMX;  \n' +
    '  .odoc..;c:;. .\'..   lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.dNNXc  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMl  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMl  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMl  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMl  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMl  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,.OMMN:.xMMMo  lWMMx.:NMM0\'\'0MMX;  \n' +
    '  ;XMMK,\'OMMN:.xMMMo  lWMMx.:NMM0\',0MMX;  \n' +
    '  .cooc..:ool. ;ooo\'  \'ooo; .loo:..cooc.  \n')
